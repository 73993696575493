import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import App from '../components/layout/App';
import Cta from '../components/base/Cta';
import Hero from '../components/base/Hero';
import Wrapper from '../components/base/Wrapper';
import ProjectCard from '../components/base/ProjectCard';
import { color, spacing, mqUp } from '../constants/styles';
import referencesIllustration from '../images/illustrations/references.svg';

const Container = styled.div`
  padding: 0 0 ${spacing.XXXXL} 0;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
  ${mqUp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mqUp('desktop')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ReferencesPage = props => {
  const DATA = props.data.works.data;
  return (
    <App
      title={DATA.seo_title}
      description={DATA.seo_description}
      image={DATA.seo_image.url}
    >
      <Hero
        titleLight={DATA.title_light}
        titleBold={DATA.title_bold}
        gradient={{start: '#6ae7b6', end: '#72dbf6'}}
        illustration={referencesIllustration}
      />
      <Container>
        <Wrapper
          css={css`
            margin-bottom: ${spacing.XXL};
          `}
        >
          <Grid>
            {DATA.card.map(item => (
              <ProjectCard
                key={item.title}
                title={item.title}
                thumb={item.thumb}
                content={item.content.html}
                tags={item.tags.html}
              />
            ))}
          </Grid>
          <div
            css={css`
              text-align: center;
              margin-top: 6.4rem;
            `}
          >
            <Cta
              to="https://trustfolio.co/profil/alqemist-nJFQfPenW/"
              theme="neutral"
            >
              Nos clients nous recommandent 👍
            </Cta>
          </div>
        </Wrapper>
      </Container>
    </App>
  );
};

export default ReferencesPage;

export const pageQuery = graphql`
  query ReferencesQuery {
    works: prismicWorks {
      data {
        seo_title
        seo_description
        seo_image {
          url
        }
        title_light
        title_bold
        card {
          title
          content {
            html
          }
          tags {
            html
          }
          thumb {
            url
          }
        }
      }
    }
  }
`;
